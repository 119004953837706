<template>
  <div class="tfw-login">
    <CRow class="mx-0">
      <div class="content col-md-6">
        <h1 class="text-white heading-1">Login</h1>
        <div class="px-0">
          <CForm @submit.prevent="loginSubmit">
            <CRow class="mt-3">
              <label class="ml-3 text-white">Email Address</label>
              <CCol class="text-right">
                <span
                  class="login-hint text-primary"
                  v-c-tooltip="'Your email is your username!'"
                  >Login Hint</span
                >
              </CCol>
            </CRow>
            <CInput
              name="login-email"
              autocomplete="off"
              v-model="username"
              tabindex="1"
            ></CInput>
            <CRow>
              <label class="ml-3 text-white pt-2">Password</label>
            </CRow>
            <CInput
              name="login-password"
              type="password"
              v-model="password"
              autocomplete="new-password"
              tabindex="2"
            ></CInput>
            <CRow>
              <CCol md="6">
                <button
                  name="registration-submit"
                  style="width: 100%"
                  class="btn pl-5 pr-5 btn-primary btn-tfw"
                  type="submit"
                >
                  {{ !isLoginFetching ? "Login" : "Loading..." }}
                </button>
              </CCol>
              <CCol class="text-right">
                <CButton
                  @click="redirectToForgot"
                  color="link"
                  class="px-0"
                  style="color: #fff;"
                >Forgot password?</CButton>
              </CCol>
            </CRow>
          </CForm>
          <div class="text-white mt-4 fade-in" v-if="isNewCandidate">
            <h5 class="font-weight-bold">Username and/or Password not recognised.</h5>
            <span class="h5 mr-3 font-weight-bold mb-2">Please try again... or</span>
            <CButton
              :to="redirectToSignUp"
              color="primary"
              class="btn-tfw">
              REGISTER NOW
            </CButton>  
          </div>
        </div>
      </div>
    </CRow>

    <img
      src="/img/tfw/hero-image.png"
      sizes="(max-width: 479px) 80vw, (max-width: 991px) 70vw, 100vw"
      style=""
      alt=""
      class="landing-image"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { LOGIN_URL, getLocalOrgDetail } from "@/helpers/helper";
export default {
  name: "TFWLogin",
  data() {
    return {
      username: "",
      password: "",
      isNewCandidate: false,
    };
  },
  computed: {
    ...mapGetters(["isLoginFetching", "isAcronymBasedLogin"]),
    redirectToSignUp() {
      let { path_acronym, customer_uid, organisation_id, location_id } = 
        getLocalOrgDetail();

      let path = `/${path_acronym}/register/${customer_uid}`;

      if (organisation_id) {
        path += `/organisation/${organisation_id}`;
      }
      
      if (location_id) {
        path += `/location/${location_id}`;
      }

      return path;
    },
  },
  methods: {
    ...mapActions(["showToast", "login", "isEmailBelongsToAcronym"]),
    loginSubmit() {
      if (this.username && this.password) {
        const data = {
          username: this.username.toLowerCase().trim(),
          password: this.password,
        };
        this.isEmailBelongsToAcronym(data.username).then((res) => {
          if (res?.status === 200) {
            this.login(data);
          }
          else if(res?.response?.status === 400) {
            this.isNewCandidate = true
          }
        });
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill the credentials",
        });
      }
    },
    redirectToForgot() {
      this.$router.push({
        path: `${
          this.isAcronymBasedLogin ? `${LOGIN_URL()}/forgot` : "/forgot"
        }`,
      });
    },
  },
};
</script>
<style scoped>
.fade-in {
    animation: fadeZoomIn 1s ease-in-out;
  }

  @keyframes fadeZoomIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
</style>